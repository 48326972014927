import axios from "axios"
import { v4 as uuidv4 } from 'uuid'

export function GetUserSettings(token) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/user_settings?&id_owner=", token.payload.sub),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function GetAllUserSettingsList(token) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/user_settings/all"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function UpdateUserSettings(token, userSettingsData) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/user_settings"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      data: userSettingsData
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}


export function GetCustomersList(token) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/customers/all?id_owner=", token.payload.sub),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function GetAllCustomersList(token) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/customers/all"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function GetCustomer(token, customerId, ownerId = null) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/customers?id=",customerId,"&id_owner=", ownerId ? ownerId : token.payload.sub),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function GetCustomerTotals(token, customerId, ownerId = null) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/customers/totals?id=",customerId,"&id_owner=", ownerId ? ownerId : token.payload.sub),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function UpdateCustomer(token, customerData) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/customers"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      data: customerData
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function CreateCustomer(navigate, t, token, ownerId = null) {
  const customer_guid = uuidv4()
  var dataObject
  var child_guid
  var defaultQuoteConditions
  try {
    defaultQuoteConditions = require('../../defaults/quoteConditions.json')
  }
  catch(e) {
    defaultQuoteConditions = []
  }
  for(var i = 0; i < defaultQuoteConditions.length; i++) {  
    child_guid = uuidv4()
    dataObject = { id: child_guid, id_customer: customer_guid, id_owner: ownerId, condition_order: i + 1, condition_name: defaultQuoteConditions[i].name, condition_contents: defaultQuoteConditions[i].contents } 
    UpdateQuoteCondition(token, dataObject)
  }
  child_guid = uuidv4()
  dataObject = { id: child_guid, id_customer: customer_guid, id_owner: ownerId, cashdrawer_name: t("cashdrawer_default_name"), cashdrawer_default_amount: 150 } 
  UpdateCashDrawer(token, dataObject)
  dataObject = { id: customer_guid, id_owner: ownerId }  
  UpdateCustomer(token, dataObject)
  .then(navigate("/customer/".concat(customer_guid,"/1")))
}

export function DeleteCustomer(token, customerId, ownerId = null) {
  return new Promise((resolve, reject) => {
    axios.all([
      axios({
        method: 'delete',
        url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/quote_conditions/by_customer?id_customer=", customerId, "&id_owner=", ownerId ? ownerId : token.payload.sub),
        headers: {
          'Authorization': `Bearer ${token['access_token']}`,
          'Content-Type': 'application/json'
        }
      }),
      axios({
        method: 'delete',
        url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/cash_drawers/by_customer?id_customer=", customerId, "&id_owner=", ownerId ? ownerId : token.payload.sub),
        headers: {
          'Authorization': `Bearer ${token['access_token']}`,
          'Content-Type': 'application/json'
        }
      }),
      axios({
        method: 'delete',
        url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/employees/by_customer?id_customer=", customerId, "&id_owner=", ownerId ? ownerId : token.payload.sub),
        headers: {
          'Authorization': `Bearer ${token['access_token']}`,
          'Content-Type': 'application/json'
        }
      }),
      axios({
        method: 'delete',
        url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/hardware/by_customer?id_customer=", customerId, "&id_owner=", ownerId ? ownerId : token.payload.sub),
        headers: {
          'Authorization': `Bearer ${token['access_token']}`,
          'Content-Type': 'application/json'
        }
      }),
      axios({
        method: 'delete',
        url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/list_settings/by_customer?id_customer=", customerId, "&id_owner=", ownerId ? ownerId : token.payload.sub),
        headers: {
          'Authorization': `Bearer ${token['access_token']}`,
          'Content-Type': 'application/json'
        }
      }),
      axios({
        method: 'delete',
        url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/customers?id=", customerId, "&id_owner=", ownerId ? ownerId : token.payload.sub),
        headers: {
          'Authorization': `Bearer ${token['access_token']}`,
          'Content-Type': 'application/json'
        }
      })
      ])
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function GetSubscriptionsList(token) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/subscriptions/all"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function DeleteAllSubscriptions(token) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'delete',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/subscriptions/all"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function GetListSettingsList(token, customerId, ownerId = null) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/list_settings/by_customer?id_owner=", ownerId ? ownerId : token.payload.sub, "&id_customer=", customerId),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function GetListSettingsListByListName(token, customerId, listName, ownerId = null) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/list_settings/by_customer_and_list_name?id_owner=", ownerId ? ownerId : token.payload.sub, "&id_customer=", customerId, "&list_name=", listName),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function UpdateListSetting(token, listSettingData) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/list_settings"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      data: listSettingData
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function GetProductsList(token, categoryId) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/products/by_category?id_category=", categoryId),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function GetProduct(token, productId) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/products?id=", productId),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}


export function GetHardware(token, customerId, productId) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/hardware/by_customer_and_product?id_owner=", token.payload.sub, "&id_customer=", customerId, "&id_product=", productId),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function GetAllHardwareList(token, customerId, ownerId = null) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/hardware/by_customer?id_owner=", ownerId ? ownerId : token.payload.sub, "&id_customer=", customerId),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function GetHardwareList(token, customerId, hardwareType, ownerId = null) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/hardware/by_customer_and_type?id_owner=", ownerId ? ownerId : token.payload.sub, "&id_customer=", customerId, "&hardware_type=", hardwareType),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function GetSuppliersList(token, customerId, ownerId = null) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/suppliers?id_owner=", ownerId ? ownerId : token.payload.sub, "&id_customer=", customerId),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function UpdateHardware(token, hardwareData) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/hardware"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      data: hardwareData
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function DeleteHardware(token, hardwareId) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'delete',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/hardware?id=", hardwareId, "&id_owner=", token.payload.sub),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}


export function GetEmployeesList(token, customerId, ownerId = null) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/employees/by_customer?id_owner=", ownerId ? ownerId : token.payload.sub, "&id_customer=", customerId),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function UpdateEmployee(token, employeeData) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/employees"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      data: employeeData
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function DeleteEmployee(token, employeeId) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'delete',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/employees?id=", employeeId, "&id_owner=", token.payload.sub),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}


export function GetCashDrawersList(token, customerId, ownerId = null) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/cash_drawers/by_customer?id_owner=", ownerId ? ownerId : token.payload.sub, "&id_customer=", customerId),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function UpdateCashDrawer(token, cashDrawerData) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/cash_drawers"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      data: cashDrawerData
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function DeleteCashDrawer(token, cashDrawerId) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'delete',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/cash_drawers?id=", cashDrawerId, "&id_owner=", token.payload.sub),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}


export function GetQuoteConditionsList(token, customerId, ownerId = null) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/quote_conditions/by_customer?id_owner=", ownerId ? ownerId : token.payload.sub, "&id_customer=", customerId),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function UpdateQuoteCondition(token, conditionData) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/quote_conditions"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      data: conditionData
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function DeleteQuoteCondition(token, conditionId) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'delete',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/quote_conditions?id=", conditionId, "&id_owner=", token.payload.sub),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function GetCustomerDataFromCoc(token, customerId) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/chamber_of_commerce/import?id_customer=",customerId,"&id_owner=", token.payload.sub),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function ConditionsMet(customerData, listSettings, conditions, conditions_required=null) {
  if(conditions) {
    var conditionsMet = 0;
    for(var i = 0; i < conditions.length; i++) {
      var value = conditions[i].field ? customerData?.[conditions[i].field] : conditions[i].list_setting ? listSettings?.[conditions[i].list_setting.list_name]?.[conditions[i].list_setting.identifier]?.[conditions[i].list_setting.name]: null
      if(conditions[i].operation === "=" && value === conditions[i].value) {
        conditionsMet++
      }
      if(conditions[i].operation === ">" && value > conditions[i].value) {
        conditionsMet++
      }
      if(conditions[i].operation === "<" && value < conditions[i].value) {
        conditionsMet++
      }
      if(conditions[i].operation === ">=" && value >= conditions[i].value) {
        conditionsMet++
      }
      if(conditions[i].operation === "<=" && value <= conditions[i].value) {
        conditionsMet++
      }
    }
    return conditions_required ? conditionsMet >= conditions_required : conditionsMet === conditions.length
  }
  return true
}