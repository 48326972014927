import Card from "../components/card"
import AllCustomersTable from "../components/allCustomersTable"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getToken } from "../redux/dataslices/tokenSlice"
import { useSnackbar } from "notistack"
import { getAllCustomers, setAllCustomers, getAllUserSettings, setAllUserSettings } from "../redux/dataslices/inventarisationSlice"
import { GetAllCustomersList, GetAllUserSettingsList, GetCustomerTotals, UpdateCustomer, DeleteCustomer } from "../helpers/dataHandlers/Inventarisation"
import { useOutletContext } from "react-router-dom"

function AllCustomers() {
  const token = useSelector(getToken)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [loadedTotals, setLoadedTotals] = useState(false)
  const allCustomers = useSelector(getAllCustomers)
  const [allCustomersData, setAllCustomersData] = useState()
  const allUserSettings = useSelector(getAllUserSettings)
  const context = useOutletContext()

  const deleteCustomer = (customerId, ownerId) => {
    DeleteCustomer(token, customerId, ownerId)
    .then(response => {
      var dataToUpdate = {...allCustomersData}
      dataToUpdate[ownerId].splice(dataToUpdate[ownerId].map(function(o) { return o.id; }).indexOf(customerId),1)
      setAllCustomersData(dataToUpdate)
    })
    .catch(error => {
      enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
      setLoading(false)
    })
  }

  const setChanceOfSuccess = (owner_id, record_id, value) => {
    var dataToUpdate = {...allCustomersData}
    dataToUpdate[owner_id][record_id] = {...allCustomersData[owner_id][record_id], chance_of_success:value}
    setAllCustomersData(dataToUpdate)
  }

  const saveChanceOfSuccess = (owner_id, record_id) => {
    UpdateCustomer(token, { id: allCustomersData[owner_id][record_id].id, id_owner: allCustomersData[owner_id][record_id].id_owner, chance_of_success: parseInt( allCustomersData[owner_id][record_id].chance_of_success) })
    .catch(error => {
      enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
      setLoading(false)
    })
  }

  const setActive = (owner_id, record_id, value) => {
    var dataToUpdate = {...allCustomersData}
    dataToUpdate[owner_id][record_id] = {...allCustomersData[owner_id][record_id], active:value}
    UpdateCustomer(token, { id: allCustomersData[owner_id][record_id].id, id_owner: allCustomersData[owner_id][record_id].id_owner, active: value })
    .then(setAllCustomersData(dataToUpdate))
    .catch(error => {
      enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
      setLoading(false)
    })
  }

  const setCustomerTotals = (payload,id_owner, index) => {
    return function() {
      var dataToUpdate = {...allCustomersData}
      if(dataToUpdate && dataToUpdate.hasOwnProperty(id_owner) && dataToUpdate[id_owner].hasOwnProperty(index)) {
        dataToUpdate[id_owner][index] = {...dataToUpdate[id_owner][index], total_one_off_costs_excl_vat: payload.total_one_off_costs_excl_vat, total_recurring_costs_excl_vat: payload.total_recurring_costs_excl_vat}
        setAllCustomersData(dataToUpdate)
      }
    }
  }

  const customerListCardProps = {
    titleVariant: 'h6',
    titleColor: '#FF6700',
    titleWeight: 600,
    titleText: 'list_of_all_customers',
    loading: loading,
    loadingText: "loading_customers",
    data: <AllCustomersTable 
      customersData={allCustomersData} 
      userSettingsData={allUserSettings} 
      deleteCustomer={deleteCustomer}
      setChanceOfSuccess={setChanceOfSuccess}
      saveChanceOfSuccess={saveChanceOfSuccess}
      setActive={setActive}
      id_owner={token.payload.sub}
      />
  }

  useEffect(() => {
    setLoading(true)
    const fetchCustomers = async () => {
      GetAllCustomersList(token)
        .then(response => { dispatch(setAllCustomers(response.data)) })
        .catch(error => {
          enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
          setLoading(false)
        })
    }
    const fetchAllUserSettings = async () => {
      GetAllUserSettingsList(token)
        .then(response => { dispatch(setAllUserSettings(response.data)) })
        .catch(error => {
          enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
          setLoading(false)
        })
    }
    fetchCustomers()
    fetchAllUserSettings()
    // eslint-disable-next-line
  }, [token])
  
  useEffect(() => {
    context.setCustomerData(null)
    context.setListSettingsData(null)
    if (!allCustomers) return
    setLoading(true)
    let customersList = {}
    for (const value of Object.entries(allCustomers)) {
      if(!customersList.hasOwnProperty(value[1].id_owner)) {
        customersList[value[1].id_owner] = []
      }
      customersList[value[1].id_owner].push(value[1])
    }
    setLoadedTotals(false)
    setAllCustomersData(customersList)
  }, [allCustomers, context])

  useEffect(() => {
    if (allCustomersData && allUserSettings && allCustomersData !== null && allUserSettings !== null) {
      setLoading(false)
      if(!loadedTotals) {
        setLoadedTotals(true)
        const fetchTotalsData = async (customer_guid, id_owner, index) => {
          GetCustomerTotals(token, customer_guid)
            .then(response => { dispatch(setCustomerTotals(response.data, id_owner, index)) })
            .catch(error => {
              enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
              setLoading(false)
            })
        }
        for (const values of Object.values(allCustomersData)) {
          for (const value of Object.entries(values)) {
            fetchTotalsData(value[1].id, value[1].id_owner, value[0])
          }
        }
      }
    }
  // eslint-disable-next-line
  }, [allCustomersData, allUserSettings])

  return (
      <>
        <Card {...customerListCardProps} />
      </>
  )
}

export default AllCustomers
