import Card from "../components/card"
import CustomersTable from "../components/customersTable"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getToken } from "../redux/dataslices/tokenSlice"
import { useSnackbar } from "notistack"
import { getMyCustomers, setMyCustomers } from "../redux/dataslices/inventarisationSlice"
import { GetCustomersList, GetCustomerTotals, UpdateCustomer } from "../helpers/dataHandlers/Inventarisation"
import { useOutletContext } from "react-router-dom"

function Customers() {
  const token = useSelector(getToken)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [loadedTotals, setLoadedTotals] = useState(false)
  const myCustomers = useSelector(getMyCustomers)
  const [myCustomersData, setMyCustomersData] = useState()
  const context = useOutletContext()

  const setChanceOfSuccess = (record_id, value) => {
    var dataToUpdate = [...myCustomersData]
    dataToUpdate[record_id] = {...myCustomersData[record_id], chance_of_success:value}
    setMyCustomersData(dataToUpdate)
  }

  const saveChanceOfSuccess = (record_id) => {
    UpdateCustomer(token, { id: myCustomersData[record_id].id, id_owner: myCustomersData[record_id].id_owner, chance_of_success: parseInt( myCustomersData[record_id].chance_of_success) })
    .catch(error => {
      enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
      setLoading(false)
    })
  }

  const setActive = (record_id, value) => {
    var dataToUpdate = [...myCustomersData]
    dataToUpdate[record_id] = {...myCustomersData[record_id], active:value}
    UpdateCustomer(token, { id: myCustomersData[record_id].id, id_owner: myCustomersData[record_id].id_owner, active: value })
    .then(setMyCustomersData(dataToUpdate))
    .catch(error => {
      enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
      setLoading(false)
    })
  }

  const setCustomerTotals = (payload, index) => {
    return function() {
      if(myCustomersData && myCustomersData.hasOwnProperty(index)) {
        var dataToUpdate = [...myCustomersData]
        dataToUpdate[index] = {...dataToUpdate[index], total_one_off_costs_excl_vat: payload.total_one_off_costs_excl_vat, total_recurring_costs_excl_vat: payload.total_recurring_costs_excl_vat}
        setMyCustomersData(dataToUpdate)
      }
    }
  }

  const customerListCardProps = {
    titleVariant: 'h6',
    titleColor: '#FF6700',
    titleWeight: 600,
    titleText: 'list_of_customers',
    loading: loading,
    loadingText: "loading_customers",
    data: <CustomersTable 
      data={myCustomersData} 
      setChanceOfSuccess={setChanceOfSuccess}
      saveChanceOfSuccess={saveChanceOfSuccess}
      setActive={setActive}
      />
  }

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      GetCustomersList(token)
        .then(response => { dispatch(setMyCustomers(response.data)) })
        .catch(error => {
          enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
          setLoading(false)
        })
    }
    fetchData()
    // eslint-disable-next-line
  }, [token])
  
  useEffect(() => {
    context.setCustomerData(null)
    context.setListSettingsData(null)
    if (!myCustomers) return
    setLoading(true)
    let customersList = []
    for (const value of Object.entries(myCustomers)) {
      customersList.push(value[1])
    }
    setLoadedTotals(false)
    setMyCustomersData(customersList)

  }, [myCustomers, context])

  useEffect(() => {
    if (myCustomersData && myCustomersData !== null) {
      setLoading(false)
      if(!loadedTotals) {
        setLoadedTotals(true)
        const fetchTotalsData = async (customer_guid, index) => {
          GetCustomerTotals(token, customer_guid)
            .then(response => { dispatch(setCustomerTotals(response.data, index)) })
            .catch(error => {
              enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
              setLoading(false)
            })
        }
        for (const value of Object.entries(myCustomersData)) {
          fetchTotalsData(value[1].id, value[0])
        }
      }
    }
  // eslint-disable-next-line
  }, [myCustomersData])

  return (
      <>
        <Card {...customerListCardProps} />
      </>
  )
}

export default Customers
